// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-modal-testing-js": () => import("./../../../src/pages/modal-testing.js" /* webpackChunkName: "component---src-pages-modal-testing-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-products-comparison-template-js": () => import("./../../../src/templates/ProductsComparisonTemplate.js" /* webpackChunkName: "component---src-templates-products-comparison-template-js" */),
  "component---src-templates-products-page-template-js": () => import("./../../../src/templates/ProductsPageTemplate.js" /* webpackChunkName: "component---src-templates-products-page-template-js" */),
  "component---src-templates-reference-page-template-js": () => import("./../../../src/templates/ReferencePageTemplate.js" /* webpackChunkName: "component---src-templates-reference-page-template-js" */),
  "component---src-templates-rich-text-template-js": () => import("./../../../src/templates/RichTextTemplate.js" /* webpackChunkName: "component---src-templates-rich-text-template-js" */),
  "component---src-templates-second-reference-content-model-js": () => import("./../../../src/templates/SecondReferenceContentModel.js" /* webpackChunkName: "component---src-templates-second-reference-content-model-js" */),
  "component---src-templates-single-product-comparison-template-js": () => import("./../../../src/templates/SingleProductComparisonTemplate.js" /* webpackChunkName: "component---src-templates-single-product-comparison-template-js" */)
}

